import React, { Component } from 'react'

import SEO from '../components/seo'
import Bg from '../assets/images/bg.jpg'
import Allstars from '../assets/images/allstars.svg'
import Mvp from '../assets/images/mvp.svg'
import Rookie from '../assets/images/rookie.svg'
import PeoplesChamp from '../assets/images/peoples-champ.svg'
import JD from '../assets/images/jd.svg'

class IndexPage extends Component {

  render() {
  	const style = {
  		'backgroundImage': 'url('+Bg+')'
  	}
    return (
      <>
      	<SEO path="/" title="Acclaim Allstars 2020" />
				<section className="intro" style={style}>
					<div className="intro__wrapper">
						<img className="intro__logo" src={Allstars} alt="Acclaim All Stars" />
						<div className="intro__content">
							<p>An award dedicated to Australia’s best Hip Hop Artists. Vote now for Rookie of the Year, People’s Champ and MVP.</p>
						</div>
						<span className="btn btn--outline intro__btn">Voting opens October 1st</span>
					</div>
					<img className="intro__logo-1" src={Mvp} alt="MVP" />
					<img className="intro__logo-2" src={Rookie} alt="Rookie of the Year" />
					<img className="intro__logo-3" src={PeoplesChamp} alt="Peoples Champ" />
					<img className="intro__jd" src={JD} alt="Peoples Champ" />
				</section>
      </>
    )
  }
}

export default IndexPage
